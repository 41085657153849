import { hashMini } from './utils/crypto';
import { isSafari15AndAbove, isWebKit, isBraveLike } from '../utils/browser';
import { hashSlice, LowerEntropy, attempt } from './utils/helpers';
import { WEBGL_RENDERER_KNOWN_PARTS, WEBGL_CONTEXT_PARAMETERS } from './constants';

export async function getWebgl() {
  try {
    // detect lies

    const lied = false;
    const document = window.document;

    let canvas;
    let canvas2;

    if ('OffscreenCanvas' in window) {
      // @ts-ignore OffscreenCanvas
      canvas = new window.OffscreenCanvas(256, 256);
      // @ts-ignore OffscreenCanvas
      canvas2 = new window.OffscreenCanvas(256, 256);
    } else {
      canvas = document.createElement('canvas');
      canvas2 = document.createElement('canvas');
    }

    const gl = getContext(canvas, 'webgl');
    const gl2 = getContext(canvas2, 'webgl2');

    if (!gl) {
      return;
    }

    // get data
    const params = { ...getParams(gl), ...getUnmasked(gl) };
    const params2 = { ...getParams(gl2), ...getUnmasked(gl2) };
    const VersionParam: Record<string, boolean> = {
      ALIASED_LINE_WIDTH_RANGE: true,
      SHADING_LANGUAGE_VERSION: true,
      VERSION: true,
    };
    const mismatch = Object.keys(params2).filter(
      (key) => !!params[key] && !VersionParam[key] && '' + params[key] != '' + params2[key]
    );

    const { dataURI, pixels } = getWebGLData(gl, 'webgl') || {};
    const { dataURI: dataURI2, pixels: pixels2 } = getWebGLData(gl2, 'webgl2') || {};

    const data = {
      extensions: [...getSupportedExtensions(gl), ...getSupportedExtensions(gl2)],
      pixels,
      pixels2,
      dataURI,
      dataURI2,
      parameters: {
        ...{ ...params, ...params2 },
        ...{
          antialias: gl.getContextAttributes() ? gl.getContextAttributes().antialias : undefined,
          MAX_VIEWPORT_DIMS: attempt(() => [...gl.getParameter(gl.MAX_VIEWPORT_DIMS)]),
          MAX_TEXTURE_MAX_ANISOTROPY_EXT: getMaxAnisotropy(gl),
          ...getShaderData('VERTEX_SHADER', getShaderPrecisionFormat(gl, 'VERTEX_SHADER')),
          ...getShaderData('FRAGMENT_SHADER', getShaderPrecisionFormat(gl, 'FRAGMENT_SHADER')),
          MAX_DRAW_BUFFERS_WEBGL: attempt(() => {
            const buffers = gl.getExtension('WEBGL_draw_buffers');
            return buffers ? gl.getParameter(buffers.MAX_DRAW_BUFFERS_WEBGL) : undefined;
          }),
        },
      },
    };

    const webglParams = !data.parameters
      ? undefined
      : [
          ...new Set(
            Object.values(data.parameters)
              .filter((val) => val && typeof val != 'string')
              .flat()
              .map((val) => Number(val))
          ),
        ].sort((a, b) => a - b);

    const gpuBrand = getGpuBrand(data.parameters?.UNMASKED_RENDERER_WEBGL);
    const webglParamsStr = '' + webglParams;
    const webglBrandCapabilities =
      !gpuBrand || !webglParamsStr ? undefined : hashMini([gpuBrand, webglParamsStr]);
    const webglCapabilities = !webglParams
      ? undefined
      : webglParams.reduce((acc, val, i) => acc ^ (+val + i), 0);

    return {
      ...data,
      gpu: {
        brand: gpuBrand,
        capabilities: webglCapabilities,
        parts: getWebGLRendererParts(data.parameters || {}),
        compressedGPU: compressWebGLRenderer((data.parameters || {}).UNMASKED_RENDERER_WEBGL),
      },
    };

    //   // harden gpu
    // const hardenGPU = (webgl) => {
    //   const {
    //     gpu: { confidence, compressedGPU },
    //   } = webgl;
    //   return confidence == 'low'
    //     ? {}
    //     : {

    //       };
    // };

    //   :
    //     !webgl || braveFingerprintingBlocking
    //       ? {
    //           parameters: {
    //             ...getBraveUnprotectedParameters(data.parameters),
    //             UNMASKED_RENDERER_WEBGL: compressWebGLRenderer((data.parameters || {}).UNMASKED_RENDERER_WEBGL),
    //           UNMASKED_VENDOR_WEBGL: webgl.parameters.UNMASKED_VENDOR_WEBGL,
    //           },
    //         }
    //       : {
    //           ...((gl, canvas2d) => {
    //             if ((canvas2d && canvas2d.lied) || LowerEntropy.CANVAS) {
    //               // distrust images
    //               const { extensions, gpu, lied, parameterOrExtensionLie } = gl;
    //               return {
    //                 extensions,
    //                 gpu,
    //                 lied,
    //                 parameterOrExtensionLie,
    //               };
    //             }
    //             return gl;
    //           })(webgl, canvas2d),
    //           parameters: {
    //             ...webgl.parameters,
    //             ...hardenGPU(webgl),
    //           },
    //         }
  } catch (error) {
    return undefined;
  }
}

function getContext(canvas, contextType) {
  try {
    if (contextType == 'webgl2') {
      return canvas.getContext('webgl2') || canvas.getContext('experimental-webgl2');
    }
    return (
      canvas.getContext('webgl') ||
      canvas.getContext('experimental-webgl') ||
      canvas.getContext('moz-webgl') ||
      canvas.getContext('webkit-3d')
    );
  } catch (error) {
    return;
  }
}

function getShaderPrecisionFormat(gl, shaderType) {
  if (!gl) {
    return;
  }
  const LOW_FLOAT = attempt(() => gl.getShaderPrecisionFormat(gl[shaderType], gl.LOW_FLOAT));
  const MEDIUM_FLOAT = attempt(() => gl.getShaderPrecisionFormat(gl[shaderType], gl.MEDIUM_FLOAT));
  const HIGH_FLOAT = attempt(() => gl.getShaderPrecisionFormat(gl[shaderType], gl.HIGH_FLOAT));
  const HIGH_INT = attempt(() => gl.getShaderPrecisionFormat(gl[shaderType], gl.HIGH_INT));
  return {
    LOW_FLOAT,
    MEDIUM_FLOAT,
    HIGH_FLOAT,
    HIGH_INT,
  };
}

function getShaderData(name, shader) {
  const data = {};
  // eslint-disable-next-line guard-for-in
  for (const prop in shader) {
    const obj = shader[prop];
    data[name + '.' + prop + '.precision'] = obj ? attempt(() => obj.precision) : undefined;
    data[name + '.' + prop + '.rangeMax'] = obj ? attempt(() => obj.rangeMax) : undefined;
    data[name + '.' + prop + '.rangeMin'] = obj ? attempt(() => obj.rangeMin) : undefined;
  }
  return data;
}

function getMaxAnisotropy(gl) {
  if (!gl) {
    return;
  }
  const ext =
    gl.getExtension('EXT_texture_filter_anisotropic') ||
    gl.getExtension('MOZ_EXT_texture_filter_anisotropic') ||
    gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic');
  return ext ? gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT) : undefined;
}

function getParams(gl) {
  if (!gl) {
    return {};
  }
  const pnames = Object.getOwnPropertyNames(Object.getPrototypeOf(gl)).filter((name) =>
    WEBGL_CONTEXT_PARAMETERS.has(name)
  );

  return pnames.reduce((acc, name) => {
    const val = gl.getParameter(gl[name]);
    if (!!val && 'buffer' in Object.getPrototypeOf(val)) {
      acc[name] = [...val];
    } else {
      acc[name] = val;
    }
    return acc;
  }, {});
}

const getUnmasked = (gl) => {
  const ext = !!gl ? gl.getExtension('WEBGL_debug_renderer_info') : null;
  return !ext
    ? {}
    : {
        UNMASKED_VENDOR_WEBGL: gl.getParameter(ext.UNMASKED_VENDOR_WEBGL),
        UNMASKED_RENDERER_WEBGL: gl.getParameter(ext.UNMASKED_RENDERER_WEBGL),
      };
};

const getSupportedExtensions = (gl) => {
  if (!gl) {
    return [];
  }
  const ext = attempt(() => gl.getSupportedExtensions());
  if (!ext) {
    return [];
  }
  return ext;
};

function getWebGLData(gl, contextType) {
  if (!gl) {
    return {
      dataURI: undefined,
      pixels: undefined,
    };
  }
  try {
    draw(gl);
    const { drawingBufferWidth, drawingBufferHeight } = gl;
    let dataURI = '';
    if (gl.canvas.constructor.name === 'OffscreenCanvas') {
      const canvas = document.createElement('canvas');
      draw(getContext(canvas, contextType));
      dataURI = canvas.toDataURL();
    } else {
      dataURI = gl.canvas.toDataURL();
    }

    // reduce excessive reads to improve performance
    const width = drawingBufferWidth / 15;
    const height = drawingBufferHeight / 6;
    const pixels = new Uint8Array(width * height * 4);
    try {
      gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
    } catch (error) {
      return {
        dataURI,
        pixels: undefined,
      };
    }
    return {
      dataURI,
      pixels: [...pixels],
    };
  } catch (error) {
    return undefined;
  }
}

function draw(gl) {
  if (!gl || isSafari15AndAbove()) {
    return;
  }

  gl.clear(gl.COLOR_BUFFER_BIT);

  // based on https://github.com/Valve/fingerprintjs2/blob/master/fingerprint2.js
  const vertexPosBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexPosBuffer);
  const vertices = new Float32Array([-0.9, -0.7, 0, 0.8, -0.7, 0, 0, 0.5, 0]);
  gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

  // create program
  const program = gl.createProgram();

  // compile and attach vertex shader
  const vertexShader = gl.createShader(gl.VERTEX_SHADER);
  gl.shaderSource(
    vertexShader,
    `
			attribute vec2 attrVertex;
			varying vec2 varyinTexCoordinate;
			uniform vec2 uniformOffset;
			void main(){
				varyinTexCoordinate = attrVertex + uniformOffset;
				gl_Position = vec4(attrVertex, 0, 1);
			}
		`
  );
  gl.compileShader(vertexShader);
  gl.attachShader(program, vertexShader);

  // compile and attach fragment shader
  const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
  gl.shaderSource(
    fragmentShader,
    `
			precision mediump float;
			varying vec2 varyinTexCoordinate;
			void main() {
				gl_FragColor = vec4(varyinTexCoordinate, 1, 1);
			}
		`
  );
  gl.compileShader(fragmentShader);
  gl.attachShader(program, fragmentShader);

  // use program
  const componentSize = 3;
  gl.linkProgram(program);
  gl.useProgram(program);
  program.vertexPosAttrib = gl.getAttribLocation(program, 'attrVertex');
  program.offsetUniform = gl.getUniformLocation(program, 'uniformOffset');
  gl.enableVertexAttribArray(program.vertexPosArray);
  gl.vertexAttribPointer(program.vertexPosAttrib, componentSize, gl.FLOAT, false, 0, 0);
  gl.uniform2f(program.offsetUniform, 1, 1);

  // draw
  const numOfIndices = 3;
  gl.drawArrays(gl.LINE_LOOP, 0, numOfIndices);
  return gl;
}

export const getWebGLRendererParts = (x) => {
  const parts = WEBGL_RENDERER_KNOWN_PARTS.filter((name) => ('' + x).includes(name));
  return [...new Set(parts)].sort().join(', ');
};

export const hardenWebGLRenderer = (x) => {
  const gpuHasKnownParts = getWebGLRendererParts(x).length;
  return gpuHasKnownParts ? compressWebGLRenderer(x) : x;
};

export const getWebGLRendererConfidence = (x) => {
  if (!x) {
    return;
  }
  const parts = getWebGLRendererParts(x);
  const hasKnownParts = parts.length;
  const hasBlankSpaceNoise = /\s{2,}|^\s|\s$/.test(x);
  const hasBrokenAngleStructure = /^ANGLE/.test(x) && !(/^ANGLE \((.+)\)/.exec(x) || [])[1];

  const valid = hasKnownParts && !hasBlankSpaceNoise && !hasBrokenAngleStructure;

  const warnings = new Set([
    hasBlankSpaceNoise ? 'found extra spaces' : undefined,
    hasBrokenAngleStructure ? 'broken angle structure' : undefined,
  ]);

  warnings.delete(undefined);

  return {
    parts,
    warnings: [...warnings],
  };
};

// WebGL Renderer helpers
export function compressWebGLRenderer(x: string): string | undefined {
  if (!x) return;

  return ('' + x)
    .replace(
      /ANGLE \(|\sDirect3D.+|\sD3D.+|\svs_.+\)|\((DRM|POLARIS|LLVM).+|Mesa.+|(ATI|INTEL)-.+|Metal\s-\s.+|NVIDIA\s[\d|\.]+/gi,
      ''
    )
    .replace(/(\s(ti|\d{1,2}GB|super)$)/gi, '')
    .replace(/\s{2,}/g, ' ')
    .trim()
    .replace(/((r|g)(t|)(x|s|\d) |Graphics |GeForce |Radeon (HD |Pro |))(\d+)/i, (...args) => {
      return `${args[1]}${args[6][0]}${args[6].slice(1).replace(/\d/g, '0')}s`;
    });
}

function getGpuBrand(gpu: string): string | null {
  if (!gpu) return null;
  const gpuBrandMatcher =
    /(adreno|amd|apple|intel|llvm|mali|microsoft|nvidia|parallels|powervr|samsung|swiftshader|virtualbox|vmware)/i;

  const brand = /radeon/i.test(gpu)
    ? 'AMD'
    : /geforce/i.test(gpu)
      ? 'NVIDIA'
      : (gpuBrandMatcher.exec(gpu)?.[0] || 'other').toLocaleUpperCase();

  return brand;
}
