// Browser fingerprint modified and based on
// https://github.com/abrahamjuliot/creepjs
// https://broprintjs.netlify.app/ and
// https://github.com/fingerprintjs/fingerprintjs

import type { MCFXTracker } from '../declarations';
import { getApplePayState } from './apple-pay';
import { getArchitecture } from './architecture';
import { getAudio } from './audio';
import { getColorGamut } from './color-gamut';
import { getCanvas } from './canvas';
import { getCSS } from './css';
import { getCSSMedia } from './cssmedia';
import { getHTMLElementVersion } from './document';
import { getDomRect } from './domrect';
import { getConsoleErrors } from './errors';
import { getFonts } from './fonts';
import { getIntl } from './intl';
import { getMath } from './math';
import { getMedia } from './media';
import { getNavigator } from './navigator';
import { getServer } from './server';
import { getScreen } from './screen';
import { getVoices } from './speech';
import { getSVG } from './svg';
import { getTimezone } from './timezone';
import { getWebgl } from './webgl';
import { getWindowFeatures } from './window';
import { getWebRTC } from './webrtc';
import { getStackBytes } from './stackbytes';
import { hash } from './utils/crypto';
import { createPhantomIframe } from './utils/helpers';

export async function fingerprint(tracker: MCFXTracker) {
  try {
    const result = await Promise.race([
      getFingerprint(tracker),
      new Promise((_, reject) => setTimeout(() => reject(new Error('Fingerprint timeout')), 2000)),
    ]);
    return result;
  } catch (error) {
    return null;
  }
}

export async function getFingerprint(tracker: MCFXTracker) {
  const { iframeWindow: phantomWindow, div: phantomDiv } = createPhantomIframe() || {};

  // @ts-ignore
  const [
    server,
    applePay,
    architecture,
    voices,
    audio,
    webgl,
    canvas,
    colorGamut,
    windowFeatures,
    htmlElementVersion,
    css,
    cssMedia,
    screen,
    math,
    consoleErrors,
    timezone,
    domRect,
    fonts,
    media,
    stackBytes,
    svg,
    intl,
    navigator,
    webRTC,
  ] = await Promise.all([
    // @ts-ignore
    getServer(tracker),
    getApplePayState(),
    getArchitecture(),
    getVoices(),
    getAudio(),
    getWebgl(),
    getCanvas(),
    getColorGamut(),
    getWindowFeatures(phantomWindow),
    getHTMLElementVersion(),
    getCSS(),
    getCSSMedia(phantomWindow),
    getScreen(),
    getMath(),
    getConsoleErrors(),
    getTimezone(),
    getDomRect(phantomWindow),
    getFonts(phantomWindow),
    getMedia(),
    getStackBytes(),
    getSVG(phantomWindow),
    getIntl(),
    getNavigator(),
    getWebRTC(),
  ]);

  // const fingerprint = {
  //   ip: tracker.session.get('location').ip,
  //   // ...server,
  //   // applePay,
  //   // architecture,
  //   // audio,
  //   // math,
  //   // navigator,
  //   // screen,
  //   // htmlElementVersion,
  //   // media,
  //   // canvas,
  //   // webgl,
  //   // cssMedia,
  //   // css,
  //   // timezone,
  //   // fonts,
  //   // webRTC,
  //   // windowFeatures,
  //   // voices,
  //   // colorGamut,
  //   // // consoleErrors,
  //   // domRect,
  //   // stackBytes,
  //   // svg,
  //   // intl,
  // };

  const [
    // ipHash,
    applePayHash,
    architectureHash,
    voicesHash,
    audioHash,
    webglHash,
    canvasHash,
    colorGamutHash,
    windowFeaturesHash,
    htmlElementVersionHash,
    cssHash,
    cssMediaHash,
    screenHash,
    mathHash,
    consoleErrorsHash,
    timezoneHash,
    domRectHash,
    fontsHash,
    mediaHash,
    stackBytesHash,
    svgHash,
    intlHash,
    navigatorHash,
    webRTCHash,
    // fingerprintHash,
  ] = await Promise.all([
    // @ts-ignore
    hash(applePay),
    hash(architecture),
    hash(voices),
    hash(audio),
    hash(webgl),
    hash(canvas),
    hash(colorGamut),
    hash(windowFeatures),
    hash(htmlElementVersion),
    hash(css),
    hash(cssMedia),
    hash(screen),
    hash(math),
    hash(consoleErrors),
    hash(timezone),
    hash(domRect),
    hash(fonts),
    hash(media),
    hash(stackBytes),
    hash(svg),
    hash(intl),
    hash(navigator),
    hash(webRTC),
  ]);

  const fingerprint = [
    tracker.session.get('location').ip,
    server.ja3,
    server.ja4,
    server.http2,
    server.country,
    server.region,
    server.city,
    server.coordinates,
    applePayHash,
    architectureHash,
    voicesHash,
    audioHash,
    webglHash,
    canvasHash,
    colorGamutHash,
    windowFeaturesHash,
    htmlElementVersionHash,
    cssHash,
    cssMediaHash,
    screenHash,
    mathHash,
    consoleErrorsHash,
    timezoneHash,
    domRectHash,
    fontsHash,
    mediaHash,
    stackBytesHash,
    svgHash,
    intlHash,
    navigatorHash,
    webRTCHash,
  ];

  const fullHash = await hash(fingerprint);

  // clean up phantom elements
  if (phantomDiv) {
    phantomDiv.remove();
  }

  return [fullHash, ...fingerprint];
}
